import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from '../utils/cookies';
import { closeEventModal } from '../redux/actions/state';
import Modal from 'react-modal';
import Button from './button';
import TextField from './textfield';
import DateSelect from './dateselect';
import { createEvent } from '../redux/actions/createApi';
import { updateEvent } from '../redux/actions/updateApi';

const EventModal = () => {
  const dispatch = useDispatch();
  const selected = useSelector((state) => state.state.eventModalItem);
  const formType = useSelector((state) => state.state.eventModalType);
  const IsOpen = useSelector((state) => state.state.eventModalOpen);
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('0');
  const [date, setDate] = useState(new Date());
  const [location, setLocation] = useState('');
  const [img, setImg] = useState('');
  const [soldout, setSoldOut] = useState("false");
  const [ticket, setTicket] = useState("");

  useEffect(() => {
    if (formType === 'update') {
      console.log(selected)
      setId(selected.id)
      setTitle(selected.title)
      setDescription(selected.description)
      setPrice(selected.price)
      setLocation(selected.location)
      setImg(selected.img)
      setTicket(selected.ticket)
      setSoldOut(selected.soldout)
      setDate(new Date(selected.date))
    }
  }, []);

  useEffect(() => {
    if (formType === 'update') {
      setId(selected.id)
      setTitle(selected.title)
      setDescription(selected.description)
      setPrice(selected.price)
      setLocation(selected.location)
      setImg(selected.img)
      setTicket(selected.ticket)
      setSoldOut(selected.soldout)
      setDate(new Date(selected.date))
    }
  }, [selected]);

  const onSubmit = () => {
    if (formType === 'new') {
      const data = {
        title,
        description,
        price,
        location,
        img,
        date,
        soldout,
        ticket
      }
      dispatch(createEvent(data))
      dispatch(closeEventModal())
    } else {
      const data = {
        title,
        description,
        price,
        location,
        img,
        date,
        id,
        soldout,
        ticket
      }
      dispatch(updateEvent(data))
      dispatch(closeEventModal())
    }
  };

  return (
    <div>
      <Modal
        isOpen={IsOpen}
        onRequestClose={() => dispatch(closeEventModal())}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="flex  flex-col px-10">
          <div className="text-xl my-4 text-white font-bold text-center mb-8">
            {formType === 'new' ? 'Új előadás' : 'Előadás módosítása'}
          </div>
          <TextField
            type="text"
            label="Cím"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            type="text"
            label="Leírás"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            type="number"
            label="Ár:"
            name="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          <TextField
            type="text"
            label="Helyszín"
            name="location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
          <DateSelect
            value={date}
            label="Dátum"
            onChange={(date) => setDate(date)}
          />
          <TextField
            type="text"
            label="Kép"
            name="img"
            value={img}
            onChange={(e) => setImg(e.target.value)}
          />
          <TextField
            type="text"
            label="Jegy (PDF) url:"
            name="ticket"
            value={ticket}
            onChange={(e) => setTicket(e.target.value)}
          />
          <div style={{ display: 'flex', flexDirection: 'row', width: '80%' }}>
            <div style={{ color: 'white' }}>Elfogytak a jegyek</div>
            <input
              style={{ marginTop: 4, marginLeft: 40 }}
              name="isGoing"
              type="checkbox"
              checked={soldout === "false" || soldout === false ? false : true}
              onChange={() => setSoldOut(!soldout)} />
          </div>
          <Button type="submit" className="ml-auto text-white" onClick={() => onSubmit()}>
            Küldés
          </Button>
        </div>
      </Modal>
    </div>
  );
};

const customStyles = {
  content: {
    width: '50vw',
    height: '75vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    background: '#2D2D2D',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    background: "rgba(0,0,0,0.8)",
  }
};

export default EventModal;

/* eslint react/forbid-prop-types: 0 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import show from '../images/eye.png';
import hide from '../images/lock.png';

const Password = React.forwardRef(
  ({ id, name, label, errors, groupClassName, fieldClassName }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const hasError = !!errors && !!errors[name];

    return (
      <div className="mb-4 flex flex-col">
      <div className={`relative flex flex-row items-center`}>
        <div className="flex flex-row w-full">
          <label htmlFor={id || name} className={`w-1/3 mr-4`}>
            {label}
          </label>
          <input
            name={name}
            id={id || name}
            type={showPassword ? 'text' : 'password'}
            ref={ref}
            className={`w-7/12 border-primary border rounded px-4 py-1 outline-none`}
          />
          {showPassword ? (
            <button
              type="button"
              className="w-1/12 font-semibold text-muted text-sm"
              onClick={() => setShowPassword(false)}
              tabIndex="-1"
            >
              <img
                src={show}
                alt="Ne mutasd"
                className="inline-block relative h-8 "
              />
            </button>
          ) : (
            <button
              type="button"
              className="w-1/12 font-semibold text-muted text-sm"
              onClick={() => setShowPassword(true)}
              tabIndex="-1"
            >
              <img
                src={hide}
                alt="Mutasd"
                className="inline-block relative h-8"
              />
            </button>
          )}
        </div>
      </div>
      {hasError && <div className="text-red">{errors[name].message}</div>}
      </div>
    );
  }
);

Password.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  errors: PropTypes.object,
  groupClassName: PropTypes.string,
  fieldClassName: PropTypes.string,
};

Password.defaultProps = {
  id: null,
  label: null,
  errors: null,
  groupClassName: null,
  fieldClassName: null,
};

export default Password;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createUser } from '../redux/actions/auth';
import Switch from 'react-switch';
import TextField from '../components/textfield';
import PasswordField from '../components/passwordfield';
import Button from '../components/button';

function Register() {
  const dispatch = useDispatch();
  const { handleSubmit, register, errors } = useForm();
  const loginError = false;
  const loginLoading = false;

  const onSubmit = async (values) => {
    //console.log(values);
    dispatch(
      createUser(
        values.email,
        values.password,
        values.name,
        values.tax,
        values.phone,
        values.address,
        'user'
      )
    );
  };

  return (
    <div className="w-screen min-h-screen p-20 bg-grey flex items-center justify-center">
      <div className="w-5/12 h-auto p-8 bg-white shadow-md">
        Regisztráció
        {
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              type="email"
              label="Email"
              name="email"
              ref={register({
                required: 'Add meg az email címedet',
              })}
              errors={errors}
            />

            <PasswordField
              label="Jelszó"
              name="password"
              ref={register({
                required: 'Válassz egy jelszót',
              })}
              errors={errors}
            />
            <TextField
              type="text"
              label="Név"
              name="name"
              ref={register({
                required: 'Add meg a nevedet',
              })}
              errors={errors}
            />
            <TextField
              type="number"
              label="Telefonszám"
              name="phone"
              ref={register({
                required: 'Add meg a telefonszámodat',
              })}
              errors={errors}
            />

            <TextField
              type="text"
              label="Adószám"
              name="tax"
              ref={register()}
              errors={errors}
            />

            <TextField
              type="text"
              label="Cím"
              name="address"
              ref={register({
                required: 'Add meg az ',
              })}
              errors={errors}
            />

            <div className="text-red-700">
              {loginError ? (
                <span className="inline-block text-sm mt-1">
                  Regisztráció hiba
                </span>
              ) : null}
            </div>

            <div className="flex mt-8 mb-8">
              <Button
                type="submit"
                size="md"
                className="ml-auto"
                loading={loginLoading}
              >
                Regisztráció
              </Button>
            </div>

            <div>
              <span className="inline-block mr-2 text-sm">Van már fiókod?</span>
              <Link
                to="/login"
                className="text-sm text-link-color hover:text-primary-600
      transition-all duration-200 ease-in-out"
              >
                Belépés
              </Link>
            </div>
          </form>
        }
      </div>
    </div>
  );
}

export default Register;

import { getUsers, getOrders, getProducts, getEvents } from './api';

const qs = require('query-string');

const apiURL = "https://enszinhazambackend.herokuapp.com";
//const apiURL = 'http://localhost:9000';
const masterKey = '2w7I63elUBAWPBT8V37MY4xFZjFpMJnR';
const axios = require('axios');

export function createEvent(eventData) {
    return async (dispatch, getState) => {
        var data = qs.stringify({
            'access_token': getState().auth.token,
            ...eventData,
        });
        var config = {
            method: 'post',
            url: apiURL + '/events',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        return axios(config)
            .then(function (response) {
                console.log(response)
                dispatch(getEvents())
            })
            .catch(function (error) {
                console.log(error);
            });
    };
}
export function createProduct(productData) {
    return async (dispatch, getState) => {
        var data = qs.stringify({
            'access_token': getState().auth.token,
            ...productData,
        });
        var config = {
            method: 'post',
            url: apiURL + '/products',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        return axios(config)
            .then(function (response) {
                console.log(response)
                dispatch(getProducts())
            })
            .catch(function (error) {
                console.log(error);
            });
    };
}

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../components/button';
import { getProducts } from '../redux/actions/api';
import { deleteProduct } from '../redux/actions/deleteApi';
import history from '../utils/history';
import ProductModal from '../components/productmodal';
import { openProductModal } from '../redux/actions/state';
import { updateProduct } from '../redux/actions/updateApi';

function Products() {
    const products = useSelector((state) => state.api.products);
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    useEffect(() => {
        if (token.length === 0) {
            history.push('/login');
        }
    }, []);
    useEffect(() => {
        dispatch(getProducts());
    }, []);

    const deleteButton = (id) => {
        dispatch(deleteProduct(id));
    };

    return (
        <div className="flex min-h-screen justify-center bg-black">
            <div className="flex flex-col w-11/12 bg-grey h-auto p-6 shadow-md m-8 rounded-xl">
                <div className="text-3xl my-4 font-bold text-center mb-8 text-white">
                    Termékek
                    <button
                        className="w-10 h-10 ml-8 justify-center rounded font-extrabold text-black mr-auto bg-blue"
                        onClick={() => dispatch(openProductModal('new'))}
                    >
                        +
                    </button>
                </div>
                {products.map((e, idx) => {
                    return (
                        <div className="grid lg:grid-cols-3 border-b m-1 p-5 text-white">
                            <div className="flex flex-col items-center lg:items-start m-2">
                                <div className="font-bold m-2 text-center">
                                    {idx}. - {e.title}
                                </div>
                                <div className="m-2">{e.price} Ft</div>
                                {/* <div className="w-96">{e.description}</div> */}
                                <div className="w-1/3 flex justify-between m-1">
                                    {e.color.map((color) => (
                                        <div
                                            style={{
                                                backgroundColor: color,
                                                borderRadius: 25,
                                                width: 25,
                                                height: 25,
                                            }}
                                        />
                                    ))}
                                    {e.size.map((size) => size + '  ')}
                                </div>
                            </div>
                            <img
                                src={e.img.split(';')[0]}
                                className="w-60 margin-auto place-self-center rounded-xl"
                                alt={e.title}
                            />
                            <div className="flex lg:flex-col justify-self-center">
                                <Button
                                    type="submit"
                                    size="sm"
                                    onClick={() => deleteButton(e.id)}
                                >
                                    Törlés
                                </Button>
                                <Button
                                    type="submit"
                                    size="sm"
                                    onClick={() =>
                                        dispatch(openProductModal('update', e))
                                    }
                                >
                                    Módosítás
                                </Button>
                                {e.size[0] !== 'ELFOGYOTT' && (
                                    <Button
                                        type="submit"
                                        size="sm"
                                        onClick={() => {
                                            let updatedElement = { ...e };
                                            updatedElement = {
                                                ...updatedElement,
                                                size: ['ELFOGYOTT'],
                                            };
                                            dispatch(
                                                updateProduct(updatedElement)
                                            );
                                        }}
                                    >
                                        Elfogyott
                                    </Button>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            <ProductModal />
        </div>
    );
}

export default Products;

export const initialState = {
    users: [],
    products: [],
    events: [],
    orders: []
};

const api = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_USERS':
            return {
                ...state,
                users: action.data,
            };
        case 'GET_PRODUCTS':
            return {
                ...state,
                products: action.data,
            };
        case 'GET_EVENTS':
            return {
                ...state,
                events: action.data,
            };
        case 'GET_ORDERS':
            return {
                ...state,
                orders: action.data,
            };
        default:
            return state;
    }
};

export default api;

export const initialState = {
  errorMessage: null,
  token: '',
  user: {},
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        token: action.data.token,
        user: action.data.user,
        errorMessage: null,
      };
    case 'UPDATE_PASSWORD_SUCCESS':
      return {
        ...state,
        user: action.data,
        errorMessage: null,
      };
    case 'GET_ME':
      return {
        ...state,
        user: action.data,
        errorMessage: null,
      };
    case 'LOGOUT':
      return {
        errorMessage: null,
        token: '',
        user: {},
      };
    case 'LOGIN_FAILED':
      return {
        ...state,
        errorMessage: action.data,
      };
    default:
      return state;
  }
};

export default auth;

export const initialState = { 
  token: '', 
  interval: 0,
  eventModalOpen: false,
  eventModalType: '',
  eventModalItem: {},
  productModalOpen: false,
  productModalType: '',
  productModalItem: {}
};

const navigation = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_INTERVAL':
      return {
        ...state,
        interval: action.data,
      };
    case 'SAVE_TOKEN_FROM_COOKIE':
      return {
        ...state,
        token: action.data,
      };
    case 'LOGOUT':
      return {
        ...state,
        token: '',
      };
      case 'OPEN_EVENT_MODAL':
      return {
        ...state,
        eventModalOpen: true,
        eventModalType: action.formType,
        eventModalItem: action.data,
      };
      case 'CLOSE_EVENT_MODAL':
      return {
        ...state,
        eventModalOpen: false,
        eventModalType: '',
        eventModalItem: {},
      };
      case 'OPEN_PRODUCT_MODAL':
      return {
        ...state,
        productModalOpen: true,
        productModalType: action.formType,
        productModalItem: action.data,
      };
      case 'CLOSE_PRODUCT_MODAL':
      return {
        ...state,
        productModalOpen: false,
        productModalType: '',
        productModalItem: {},
      };
    default:
      return state;
  }
};

export default navigation;

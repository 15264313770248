const apiURL = 'https://enszinhazambackend.herokuapp.com';
//const apiURL = 'http://localhost:9000';
const masterKey = '2w7I63elUBAWPBT8V37MY4xFZjFpMJnR';
const axios = require('axios');

export function getUsers() {
    return async (dispatch, getState) => {
        return axios
            .get(
                apiURL + '/users?access_token=' + getState().auth.token,
                {},
                {}
            )
            .then((response) => {
                dispatch({ type: 'GET_USERS', data: response.data });
            })
            .catch(function (error) {
                alert('Hiba, kérlek próbáld újra');
            });
    };
}
export function deleteUser(userid) {
    return async (dispatch, getState) => {
        return axios
            .delete(
                apiURL +
                    '/users/' +
                    userid +
                    '?access_token=' +
                    getState().auth.token,
                {},
                {}
            )
            .then((response) => {
                dispatch({ type: 'DELETE_USER' });
                dispatch(getUsers());
            })
            .catch(function (error) {
                alert('Hiba, kérlek próbáld újra');
            });
    };
}
export function getProducts() {
    return async (dispatch, getState) => {
        return axios
            .get(apiURL + '/products?access_token=' + masterKey, {}, {})
            .then((response) => {
                dispatch({ type: 'GET_PRODUCTS', data: response.data });
            })
            .catch(function (error) {
                alert('Hiba, kérlek próbáld újra');
            });
    };
}
export function getEvents() {
    return async (dispatch, getState) => {
        return axios
            .get(apiURL + '/events?access_token=' + masterKey, {}, {})
            .then((response) => {
                dispatch({ type: 'GET_EVENTS', data: response.data });
            })
            .catch(function (error) {
                console.log(error);
                alert('Hiba, kérlek próbáld újra');
            });
    };
}
export function getOrders() {
    return async (dispatch, getState) => {
        return axios
            .get(
                apiURL + '/orders?access_token=' + getState().auth.token,
                {},
                {}
            )
            .then((response) => {
                dispatch({ type: 'GET_ORDERS', data: response.data.reverse() });
            })
            .catch(function (error) {
                console.log(error);
                alert('Hiba, kérlek próbáld újra');
            });
    };
}

export const saveTokenFromCookie = (token) => (dispatch) => {
    dispatch({ type: 'SAVE_TOKEN_FROM_COOKIE', data: token });
};
export const logout = () => (dispatch) => {
    dispatch({ type: 'LOGOUT' });
};
export const openEventModal = (type, e) => (dispatch) => {
    dispatch({ type: 'OPEN_EVENT_MODAL', formType: type, data: e });
};
export const closeEventModal = (type, e) => (dispatch) => {
    dispatch({ type: 'CLOSE_EVENT_MODAL', formType: type, data: e });
};
export const openProductModal = (type, e) => (dispatch) => {
    dispatch({ type: 'OPEN_PRODUCT_MODAL', formType: type, data: e });
};
export const closeProductModal = (type, e) => (dispatch) => {
    dispatch({ type: 'CLOSE_PRODUCT_MODAL', formType: type, data: e });
};

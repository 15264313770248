import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CalendarInput = ({ value, onClick }) => (
  <button
    type="button"
    className="flex flex-1 w-64 items-center border border-primary rounded px-2 py-1 h-8"
    onClick={
      ((e) => {
        e.preventDefault();
      },
      onClick)
    }
  >
    <div className="w-full">{value}</div>
  </button>
);

const DateSelect = ({ label, value, onChange }) => {
  return (
    <div className={`relative mb-4 text-white flex flex-row items-center`}>
      <label className={`w-1/3 mr-4`}>{label}</label>
      <DatePicker
        selected={value}
        onChange={onChange}
        showTimeSelect
        timeFormat="HH:mm"
        dateFormat="yyyy/MM/dd HH:mm"
        customInput={<CalendarInput />}
        timeIntervals={1}
      />
    </div>
  );
};

export default DateSelect;

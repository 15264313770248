import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMe, updateUser } from '../redux/actions/auth';
import TextField from '../components/textfield';
import Button from '../components/button';
import { getCookie } from '../utils/cookies';
import history from '../utils/history';

function Profile() {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [tax, settax] = useState('');
  const [email, setemail] = useState('');
  const [address, setaddress] = useState('');
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    if (token.length === 0) {
      history.push('/login');
    }
  }, []);
  const onSubmit = () => {
    const fields = {
      name,
      phone,
      tax,
      email,
      address,
    };
    dispatch(updateUser(fields));
  };

  useEffect(() => {
    dispatch(getMe());
  }, []);

  useEffect(() => {
    setName(user.name);
    setPhone(user.phone);
    settax(user.tax);
    setemail(user.email);
    setaddress(user.address);
  }, [user]);

  return (
    <div className="w-screen min-h-screen flex justify-center items-start bg-black">
      <div className="flex flex-col w-1/3 bg-grey h-auto p-6 shadow-md mt-20">
        <div className="text-3xl text-white my-4 font-bold text-center mb-8">Profil</div>
        <TextField
          type="text"
          label="Név"
          name="name"
          defaultValue={name}
          onChange={(e) => setName(e.target.value)}
          disabled={user.role === 'company'}
        />
        <TextField
          type="number"
          label="Telefonszám"
          name="phone"
          defaultValue={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <TextField
          type="text"
          label="Adószám"
          name="tax"
          defaultValue={tax}
          onChange={(e) => settax(e.target.value)}
        />
        <TextField
          type="email"
          label="E-mail"
          name="email"
          defaultValue={email}
          onChange={(e) => setemail(e.target.value)}
        />

        <TextField
          type="text"
          label="Cím"
          name="address"
          defaultValue={address}
          onChange={(e) => setaddress(e.target.value)}
        />
        <div className="flex mt-16">
          <Button
            type="submit"
            size="md"
            className="ml-auto text-white"
            onClick={() => onSubmit()}
          >
            Mentés
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Profile;

import { getOrders, getProducts, getEvents } from './api';

const qs = require('query-string');

const apiURL = 'https://enszinhazambackend.herokuapp.com';
//const apiURL = 'http://localhost:9000';
// const masterKey = '2w7I63elUBAWPBT8V37MY4xFZjFpMJnR';
const axios = require('axios');

export function updateEvent(eventData) {
    return async (dispatch, getState) => {
        var data = qs.stringify({
            access_token: getState().auth.token,
            ...eventData,
        });
        var id = eventData.id;
        var config = {
            method: 'PUT',
            url: apiURL + '/events/' + id,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: data,
        };
        console.log(config);

        return axios(config)
            .then(function (response) {
                console.log(response);
                dispatch(getEvents());
            })
            .catch(function (error) {
                console.log(error);
            });
    };
}
export function updateProduct(productData) {
    console.log(`object`, productData);
    return async (dispatch, getState) => {
        var data = qs.stringify({
            access_token: getState().auth.token,
            ...productData,
        });
        var id = productData.id;
        var config = {
            method: 'PUT',
            url: apiURL + '/products/' + id,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: data,
        };
        // console.log(config);

        return axios(config)
            .then(function (response) {
                console.log(response);
                dispatch(getProducts());
            })
            .catch(function (error) {
                console.log(error);
            });
    };
}
export function updateOrder(id, status) {
    return async (dispatch, getState) => {
        var data = qs.stringify({
            access_token: getState().auth.token,
            status: status,
        });
        var config = {
            method: 'PUT',
            url: apiURL + '/orders/' + id,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: data,
        };
        console.log(config);

        return axios(config)
            .then(function (response) {
                console.log(response);
                dispatch(getOrders());
            })
            .catch(function (error) {
                console.log(error);
            });
    };
}

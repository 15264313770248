/* eslint react/forbid-prop-types: 0 */
import React from 'react';

const TextField = React.forwardRef(
  (
    {
      id,
      name,
      label,
      type,
      errors,
      groupClassName,
      fieldClassName,
      value,
      defaultValue,
      onChange,
      disabled
    },
    ref
  ) => {
    const hasError = !!errors && !!errors[name];

    return (
      <div className="flex flex-col mb-4 ">
      <div
        className={`relative flex flex-row items-center  ${
          groupClassName || ''
        }`}
      >
        <label htmlFor={id || name} className={`w-1/3 mr-4 text-white `}>
          {label}
        </label>
        <input
          name={name}
          id={id || name}
          type={type}
          ref={ref}
          disabled={disabled}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue || ''}
          className={`w-2/3 text-white bg-grey border rounded  px-4 py-1 outline-none ${
            fieldClassName || ''
          }`}
        />
      </div>
        {hasError ? <div className="text-red">{errors[name].message}</div> : null}
      </div>
    );
  }
);

export default TextField;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../components/button';
import { openEventModal } from '../redux/actions/state';
import { getEvents, getOrders } from '../redux/actions/api';
import { format } from 'date-fns';
import { deleteEvent } from '../redux/actions/deleteApi';
import history from '../utils/history';
import EventModal from '../components/eventmodal';
import { updateOrder } from '../redux/actions/updateApi';

function Events() {
    const dispatch = useDispatch();

    const events = useSelector((state) => state.api.events);
    const orders = useSelector((state) => state.api.orders);
    const token = useSelector((state) => state.auth.token);

    const [filtedOrders, setFiltedOrders] = useState({});

    useEffect(() => {
        if (token.length === 0) {
            history.push('/login');
        }
    }, []);

    useEffect(() => {
        dispatch(getEvents());
        dispatch(getOrders());
    }, []);

    const deleteButton = (id) => {
        dispatch(deleteEvent(id));
    };

    const eventEndHandel = (id) => {
        orders.map((o) => {
            if (o.status.split('_')[0] === 'KEZELT JEGY') {
                o.element.forEach((e) => {
                    if (e.eventId?.id === id) {
                        dispatch(updateOrder(o.id, 'FINISHED'));
                    }
                });
            }

            if (
                o.status === 'FINISHED' ||
                o.status.split('_')[0] === 'KEZELT JEGY'
            ) {
                o.element.forEach((e) => {
                    if (e.eventId?.id === id) {
                        console.log(`object`, e.tickets, o.status);
                    }
                });
            }
        });
        console.log(`object`, filtedOrders);
    };

    return (
        <div className="flex min-h-screen justify-center bg-black">
            <div className="flex flex-col w-11/12 bg-grey h-auto p-6 shadow-md m-8 rounded-xl">
                <div className="text-3xl font-bold text-center mb-8 text-white">
                    Események
                    <button
                        className="w-10 h-10 ml-8 justify-center rounded font-extrabold text-black mr-auto bg-blue"
                        onClick={() => dispatch(openEventModal('new'))}
                    >
                        +
                    </button>
                </div>
                {events.map((e, idx) => {
                    let newDate = new Date(e.date);
                    newDate.setDate(newDate.getDate() + 1);

                    return (
                        <div className="grid lg:grid-cols-3 border-b m-1 p-5 text-white">
                            <div className="flex flex-col items-center lg:items-start m-2 gap-2">
                                <div className="font-bold text-center">
                                    {idx}. - {e.title}
                                </div>
                                <div>
                                    {format(
                                        new Date(e.date),
                                        'yyyy-MM-dd HH:mm'
                                    )}
                                </div>
                                {/* <div className="w-96 text-lime">
                                        {e.description}
                                    </div> */}
                                <div>{e.location}</div>

                                <div>{e.price} Ft</div>
                            </div>

                            <img
                                src={e.img}
                                className="w-60 margin-auto place-self-center rounded-xl"
                                alt={e.title}
                            />
                            <div className="flex lg:flex-col justify-self-center">
                                <Button
                                    type="submit"
                                    size="sm"
                                    onClick={() => deleteButton(e.id)}
                                >
                                    Törlés
                                </Button>
                                <Button
                                    type="submit"
                                    size="sm"
                                    onClick={() =>
                                        dispatch(openEventModal('update', e))
                                    }
                                >
                                    Módosítás
                                </Button>
                                {new Date() > newDate && (
                                    <Button
                                        type="submit"
                                        size="sm"
                                        onClick={() => eventEndHandel(e.id)}
                                    >
                                        Végetért
                                    </Button>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            <EventModal />
        </div>
    );
}

export default Events;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../components/button';
import { getOrders, getUsers } from '../redux/actions/api';
import { deleteOrder } from '../redux/actions/deleteApi';
import history from '../utils/history';
import { updateOrder } from '../redux/actions/updateApi';

const UserData = (props) => {
    return (
        <div className="grid grid-cols-2 lg:m-0">
            <div className="font-bold text-right mr-4">Név</div>
            <div>{props.findUser?.name}</div>
            <div className="font-bold text-right mr-4">Email</div>
            <div>{props.findUser?.email}</div>
            <div className="font-bold text-right mr-4">Telefon</div>
            <div>{props.findUser?.phone}</div>
            <div className="font-bold text-right mr-4">Város</div>
            <div>{props.findUser?.city}</div>
            <div className="font-bold text-right mr-4">Irányítószám</div>
            <div>{props.findUser?.zip}</div>
            <div className="font-bold text-right mr-4">Cím</div>
            <div>{props.findUser?.address}</div>
        </div>
    );
};

const forintFormat = (num) =>
    new Intl.NumberFormat('hu-HU', {
        style: 'currency',
        currency: 'Huf',
        minimumFractionDigits: 0,
    }).format(num);

function Orders() {
    const orders = useSelector((state) => state.api.orders);
    const users = useSelector((state) => state?.api?.users || []);
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);

    const [filtedOrders, setFiltedOrders] = useState(orders);
    const [statusFilter, setStatusFilter] = useState('FINISHED');
    const [statusArray, setStatusArray] = useState([]);
    const [typeFilter, setTypeFilter] = useState();
    const [typeArray, setTypeArray] = useState([]);

    const [sumObj, setSumObj] = useState({});

    useEffect(() => {
        if (token.length === 0) {
            history.push('/login');
        }
    }, []);

    useEffect(() => {
        dispatch(getOrders());
        dispatch(getUsers());
    }, []);

    useEffect(() => {
        let stat = [];
        let type = [];
        let sum = {};
        sum.income = 0;

        sum.product = orders.filter(
            (o) => o.type === 'product' && o.status === 'FINISHED'
        );
        sum.event = orders.filter(
            (o) =>
                (o.type === 'event' && o.status === 'FINISHED') ||
                o.status.split('_')[0] === 'KEZELT JEGY'
        );
        sum.unpaid = orders.filter((o) => o.status === 'UNPAID');

        orders.map((o) => {
            if (!stat.includes(o.status)) stat.push(o.status);
            if (!type.includes(o.type)) type.push(o.type);
            if (o.price && o.status === 'FINISHED') {
                sum.income += Number(o.price);
            }
        });

        sum.event.sum1 = 0;
        sum.event.sum2 = 0;
        sum.event.sumPrice = 0;

        sum.event.map((e) => {
            e.element.map((_e) => {
                sum.event.sumPrice += _e.quantity * _e.price;
                _e.eventId?.id === '6165c2ed24f9ea0016df5365'
                    ? (sum.event.sum1 += Number(_e.quantity))
                    : (sum.event.sum2 += Number(_e.quantity));
            });
        });
        setSumObj(sum);
        setStatusArray(stat);
        setTypeArray(type);
    }, [orders]);

    useEffect(() => {
        let fill = orders;

        if (statusFilter && !typeFilter) {
            fill = orders.filter((o) => o.status === statusFilter);
        }
        if (typeFilter && !statusFilter) {
            fill = orders.filter((o) => o.type === typeFilter);
        }
        if (typeFilter && statusFilter) {
            fill = orders.filter(
                (o) => o.type === typeFilter && o.status === statusFilter
            );
        }
        setFiltedOrders(fill);
    }, [orders, statusFilter, typeFilter]);

    const deleteButton = (id) => {
        dispatch(deleteOrder(id));
    };
    const statusButton = (id) => {
        dispatch(updateOrder(id, 'TEJESÍTVE'));
    };

    return (
        <div className="flex justify-center bg-black min-h-screen">
            <div className="flex flex-col w-11/12 bg-grey h-auto p-6 shadow-md m-8 rounded-xl">
                <div className="text-3xl my-4 font-bold text-center mb-8 text-white">
                    Rendelések
                </div>
                <div className="flex justify-evenly">
                    <div className="grid lg:grid-cols-3 text-xl text-white items-center">
                        {statusArray?.map((s) => (
                            <div key={s} className="mx-auto">
                                <Button
                                    type="submit"
                                    size="sm"
                                    className={
                                        statusFilter === s
                                            ? 'w-40 bg-blue text-black'
                                            : 'w-40'
                                    }
                                    onClick={() => {
                                        if (statusFilter === s) {
                                            setStatusFilter();
                                        } else {
                                            setStatusFilter(s);
                                        }
                                    }}
                                >
                                    {s}
                                </Button>
                            </div>
                        ))}
                    </div>
                    <div className="grid lg:grid-cols-2 text-xl text-white items-center">
                        {typeArray?.map((t) => (
                            <div key={t} className="mx-auto">
                                <Button
                                    type="submit"
                                    size="sm"
                                    className={
                                        typeFilter === t
                                            ? 'w-40 bg-blue text-black'
                                            : 'w-40'
                                    }
                                    onClick={() => {
                                        if (typeFilter === t) {
                                            setTypeFilter();
                                        } else {
                                            setTypeFilter(t);
                                        }
                                    }}
                                >
                                    {t.toUpperCase()}
                                </Button>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row justify-center">
                    <div className="grid grid-cols-3 pb-2 text-white text-center border m-4 rounded-xl">
                        <div className="col-span-3 p-2 border-b">ORDERS</div>
                        <div className="px-5 pt-2">EVENTS</div>
                        <div className="px-5 pt-2">PRODUCTS</div>
                        <div className="px-5 pt-2">UNPAID</div>
                        <div className="px-5">{sumObj?.event?.length} db</div>
                        <div className="px-5">{sumObj?.product?.length} db</div>
                        <div className="px-5">{sumObj?.unpaid?.length} db</div>
                    </div>
                    <div className="grid grid-cols-3 pb-2 text-white text-center border m-4 rounded-xl">
                        <div className="col-span-3 p-2 border-b">INCOME</div>
                        <div className="px-5 pt-2">EVENTS</div>
                        <div className="px-5 pt-2">PRODUCTS</div>
                        <div className="px-5 pt-2">SUM</div>
                        <div className="px-5">
                            {forintFormat(sumObj?.event?.sumPrice)}
                        </div>
                        <div className="px-5">
                            {forintFormat(
                                sumObj?.income -
                                    sumObj?.product?.length * 1590 -
                                    sumObj?.event?.sumPrice
                            )}
                        </div>
                        <div className="px-5">
                            {forintFormat(
                                sumObj?.income - sumObj?.product?.length * 1590
                            )}
                        </div>
                    </div>
                    {/* <div className="grid grid-cols-2 pb-2 text-white text-center border m-4 rounded-xl">
                        <div className="col-span-2 p-2 border-b">
                            Neked szól a taps
                        </div>
                        <div className="px-2 pt-2">DEC_11</div>
                        <div className="px-2 pt-2">DEC_12</div>
                        <div className="px-2 ">{sumObj?.event?.sum1}</div>
                        <div className="px-2">{sumObj?.event?.sum2}</div>
                    </div> */}
                </div>
                {filtedOrders?.map((e, idx) => {
                    return (
                        <div
                            key={e.id}
                            className="border-b m-1 py-5 text-white"
                        >
                            <div className="grid grid-cols-1 lg:grid-cols-3 text-white w-auto">
                                <div className="font-bold lg:col-span-3">
                                    {filtedOrders?.length - idx}.
                                </div>
                                <div className="">
                                    <div className="grid grid-cols-2 lg:m-0">
                                        <div className="font-bold text-right mr-4">
                                            Státusz
                                        </div>
                                        <div>{e.status}</div>
                                        <div className="font-bold text-right mr-4">
                                            ID
                                        </div>
                                        <div>{e.id}</div>
                                        <div className="font-bold text-right mr-4">
                                            Ár
                                        </div>
                                        <div>{e.price} Huf</div>
                                        <div className="font-bold text-right mr-4">
                                            Átvétel
                                        </div>
                                        <div>{e.delivery}</div>
                                        <div className="font-bold text-right mr-4">
                                            Rendelés
                                        </div>
                                        <div>
                                            {new Date(
                                                e?.date
                                            ).toLocaleDateString()}
                                        </div>
                                    </div>
                                </div>
                                <UserData
                                    findUser={users.find(
                                        (usr) => usr.id === e.userid
                                    )}
                                />
                                <div className="flex flex-col items-center lg:items-end">
                                    {e.element.map((_e) =>
                                        _e.productId ? (
                                            <div
                                                key={_e._id}
                                                className="flex justify-center gap-4 text-blue p-2"
                                            >
                                                <div>{_e?.quantity} db</div>
                                                <div>
                                                    {_e?.productId?.title}
                                                </div>
                                                <div
                                                    style={{
                                                        width: 25,
                                                        height: 25,
                                                        background: _e.color,
                                                        borderRadius: 20,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {' '}
                                                    {_e?.size}
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                key={_e._id}
                                                className="flex justify-center gap-4 text-blue p-2 "
                                            >
                                                <div>{_e?.quantity} db</div>
                                                <div>{_e?.eventId?.title}</div>
                                                <div>
                                                    {new Date(
                                                        _e?.eventId?.date
                                                    ).toLocaleDateString()}
                                                </div>
                                            </div>
                                        )
                                    )}
                                    <div className="">
                                        {e.status === 'FINISHED' && (
                                            <Button
                                                type="submit"
                                                size="sm"
                                                // className="m-auto"
                                                onClick={() =>
                                                    statusButton(e.id)
                                                }
                                            >
                                                Teljesítve
                                            </Button>
                                        )}
                                    </div>
                                    <div className="">
                                        {(e.status === 'UNPAID' ||
                                            e.status === 'CANCELLED') && (
                                            <Button
                                                type="submit"
                                                size="sm"
                                                // className="ml-auto"
                                                red
                                                onClick={() =>
                                                    deleteButton(e.id)
                                                }
                                            >
                                                Rendelés törlése
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Orders;

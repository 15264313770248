import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from '../utils/cookies';
import { closeProductModal } from '../redux/actions/state';
import Modal from 'react-modal';
import Button from './button';
import TextField from './textfield';
import DateSelect from './dateselect';
import { createProduct } from '../redux/actions/createApi';
import { updateProduct } from '../redux/actions/updateApi';

const ProductModal = () => {
  const dispatch = useDispatch();
  const selected = useSelector((state) => state.state.productModalItem);
  const formType = useSelector((state) => state.state.productModalType);
  const IsOpen = useSelector((state) => state.state.productModalOpen);
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('0');
  const [size, setSize] = useState('');
  const [img, setImg] = useState('');
  const [color, setColor] = useState('');
  const [type, setType] = useState('');
  const [highlight, setHighlight] = useState('false');

  useEffect(() => {
    if (formType === 'update') {
      console.log(selected);
      setId(selected.id);
      setTitle(selected.title);
      setDescription(selected.description);
      setPrice(selected.price);
      setType(selected.type);
      setSize(selected.size.toString());
      setImg(selected.img);
      setHighlight(selected.highlight);
      setColor(selected.color.toString());
    }
  }, []);

  useEffect(() => {
    if (formType === 'update') {
      console.log(selected);
      setId(selected.id);
      setTitle(selected.title);
      setDescription(selected.description);
      setPrice(selected.price);
      setSize(selected.size.toString());
      setImg(selected.img);
      setHighlight(selected.highlight);
      setType(selected.type);
      setColor(selected.color.toString());
    }
  }, [selected]);

  const onSubmit = () => {
    if (formType === 'new') {
      const data = {
        title,
        description,
        price,
        size,
        img,
        color,
        type,
        highlight,
      };
      dispatch(createProduct(data));
      dispatch(closeProductModal());
    } else {
      const data = {
        title,
        description,
        price,
        size,
        img,
        id,
        color,
        type,
        highlight,
      };
      dispatch(updateProduct(data));
      dispatch(closeProductModal());
    }
  };

  return (
    <div>
      <Modal
        isOpen={IsOpen}
        onRequestClose={() => dispatch(closeProductModal())}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="flex  flex-col px-10">
          <div className="text-xl my-4 text-white font-bold text-center mb-8">
            {formType === 'new' ? 'Új termék' : 'Termék módosítása'}
          </div>
          <TextField
            type="text"
            label="Cím"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            type="text"
            label="Leírás"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            type="number"
            label="Ár:"
            name="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          <TextField
            type="text"
            label="Méret"
            name="size"
            value={size}
            onChange={(e) => setSize(e.target.value)}
          />
          <TextField
            type="text"
            label="Szín"
            name="size"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
          <TextField
            type="text"
            label="Kép"
            name="img"
            value={img}
            onChange={(e) => setImg(e.target.value)}
          />
          <TextField
            type="text"
            label="Típus (pl: Pólók)"
            name="type"
            value={type}
            onChange={(e) => setType(e.target.value)}
          />
          <div style={{ display: 'flex', flexDirection: 'row', width: '80%' }}>
            <div style={{ color: 'white' }}>Kiemelt termék</div>
            <input
              style={{ marginTop: 4, marginLeft: 40 }}
              name="isGoing"
              type="checkbox"
              checked={
                highlight === 'false' || highlight === false ? false : true
              }
              onChange={() => setHighlight(!highlight)}
            />
          </div>

          <Button
            type="submit"
            className="ml-auto text-white"
            onClick={() => onSubmit()}
          >
            Küldés
          </Button>
        </div>
      </Modal>
    </div>
  );
};

const customStyles = {
  content: {
    width: '50vw',
    height: '75vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    background: '#2D2D2D',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    background: 'rgba(0,0,0,0.8)',
  },
};

export default ProductModal;

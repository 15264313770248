import { combineReducers } from 'redux';

import state from './state';
import auth from './auth';
import api from './api';

const rootReducer = combineReducers({
  state,
  auth,
  api
});

export default rootReducer;

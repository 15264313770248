import React, { useEffect } from 'react';
import Button from '../components/button';
import { logOut } from '../redux/actions/auth';
import { Link } from 'react-router-dom';
import history from '../utils/history';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from '../utils/cookies';

function Admin() {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);

    useEffect(() => {
        if (token.length === 0) {
            history.push('/login');
        }
    }, []);

    const role = getCookie('userrole');
    const userId = getCookie('userid');

    let admin = (
        <>
            <Link to="/qr">
                <Button
                    type="button"
                    className="w-48 text-white border-blue"
                    //onClick={() => dispatch(getSettings())}
                >
                    Jegykezelés
                </Button>
            </Link>
        </>
    );

    if (role === 'admin' && userId !== 'ticket') {
        admin = (
            <>
                {' '}
                <Link to="/users">
                    <Button
                        type="button"
                        className="w-48 text-white border-blue"
                        //onClick={() => dispatch(getSettings())}
                    >
                        Felhasználók
                    </Button>
                </Link>
                <Link to="/products">
                    <Button
                        type="button"
                        className="w-48 text-white border-blue"
                        //onClick={() => dispatch(getSettings())}
                    >
                        Termékek
                    </Button>
                </Link>
                <Link to="/events">
                    <Button
                        type="button"
                        className="w-48 text-white border-blue"
                        //onClick={() => dispatch(getSettings())}
                    >
                        Események
                    </Button>
                </Link>
                <Link to="/orders">
                    <Button
                        type="button"
                        className="w-48 border-blue-400 text-white border-blue"
                        //onClick={() => dispatch(getSettings())}
                    >
                        Rendelések
                    </Button>
                </Link>
                <Link to="/qr">
                    <Button
                        type="button"
                        className="w-48 text-white border-blue"
                        //onClick={() => dispatch(getSettings())}
                    >
                        Jegykezelés
                    </Button>
                </Link>
            </>
        );
    }

    return (
        <div className="w-screen min-h-screen flex items-center justify-center bg-black">
            <div className="flex flex-col bg-black h-auto p-8 pr-12 shadow-md items-center">
                <div className="text-2xl text-center font-bold text-white">
                    Én Színházam
                </div>
                {admin}
                <Button
                    type="button"
                    className="w-48 text-white "
                    red
                    onClick={() => dispatch(logOut())}
                >
                    Kilépés
                </Button>
            </div>
        </div>
    );
}

export default Admin;

/* eslint react/button-has-type: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = ({ children, type, onClick, className, loading, red }) => {
    return (
        <button
            type={type}
            className={classNames(
                `bg-transparent border-2 rounded-lg px-4 py-2 m-2 h-12  ${
                    red
                        ? 'border-red hover:bg-red'
                        : 'border-blue hover:bg-blue '
                } hover:text-black`,
                className
            )}
            onClick={onClick}
            disabled={loading}
        >
            {children}
        </button>
    );
};

Button.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    type: PropTypes.oneOf(['button', 'submit']),
    red: PropTypes.bool,
};

Button.defaultProps = {
    children: null,
    className: '',
    onClick: () => {},
    loading: false,
    type: 'button',
    red: false,
};

export default Button;

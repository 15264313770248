import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../components/button';
import { getUsers } from '../redux/actions/api';
import { deleteUser } from '../redux/actions/deleteApi';
import history from '../utils/history';

function Users() {
    const users = useSelector((state) => state.api.users);
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    console.log(`object`, users, users.reverse());

    useEffect(() => {
        if (token.length === 0) {
            history.push('/login');
        }
    }, []);

    useEffect(() => {
        dispatch(getUsers());
    }, []);

    const deleteButton = (userid) => {
        dispatch(deleteUser(userid));
    };

    return (
        <div className="flex justify-center bg-black">
            <div className="flex flex-col bg-grey h-auto p-6 shadow-md m-8 rounded-xl">
                <div className="text-3xl my-4 font-bold text-center mb-8 text-white">
                    Felhasználók
                </div>
                {users.map((e, idx) => {
                    return (
                        <div className="flex flex-col lg:flex-row justify-between border-b m-1 p-5 text-white">
                            <div>
                                <div className="font-bold text-center lg:text-left">
                                    {idx}. {e.name}
                                </div>
                                <div className="lg:w-96 text-center lg:text-left">
                                    {e.email}
                                </div>
                                <div className="lg:w-96 text-center lg:text-left">
                                    {e.phone}
                                </div>
                            </div>
                            <div>
                                <div className="lg:w-96 text-center lg:text-left">
                                    {e.city} {e.zip}
                                </div>
                                <div className="lg:w-96 text-center lg:text-left">
                                    {e.address}
                                </div>
                            </div>
                            <Button
                                type="submit"
                                size="sm"
                                className="mx-auto"
                                onClick={() => deleteButton(e.id)}
                            >
                                Törlése
                            </Button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Users;

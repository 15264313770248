import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QrReader from 'react-qr-reader';

import Button from '../components/button';
import { getEvents, getOrders, getUsers } from '../redux/actions/api';
import history from '../utils/history';
import { updateOrder } from '../redux/actions/updateApi';
import { getCookie } from '../utils/cookies';

const QrPage = () => {
    const [guests, setGuests] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState();
    const [isScaning, setIsScaning] = useState(false);
    const [scanData, setScanData] = useState({
        message: 'Még nem szkenneltél',
        style: { backgroundColor: 'rgb(3, 233, 254)', color: 'black' },
    });
    const [filtedEvents, setFiltedEvents] = useState([]);
    const orders = useSelector((state) => state.api.orders);
    const users = useSelector((state) => state?.api?.users || []);
    const events = useSelector((state) => state?.api?.events);
    const dispatch = useDispatch();

    const token = useSelector((state) => state.auth.token);
    const userId = getCookie('userid');

    useEffect(() => {
        let filterEvents = [];
        events.sort((a, b) => new Date(a.date) - new Date(b.date));

        if (userId === 'ticket') {
            filterEvents = events.filter((e) => {
                const datePlusOne = new Date(e.date);
                datePlusOne.setDate(datePlusOne.getDate() + 1);
                return new Date(datePlusOne) > new Date();
            });
        } else {
            filterEvents = events;
        }

        setFiltedEvents(filterEvents);
        if (filterEvents.length !== 0) {
            setSelectedEvent(filterEvents[filterEvents.length - 1].id);
        }
    }, [events, userId]);

    useEffect(() => {
        if (token.length === 0) {
            history.push('/login');
        }
    }, []);

    useEffect(() => {
        dispatch(getOrders());
        dispatch(getUsers());
        dispatch(getEvents());
    }, []);

    useEffect(() => {
        let guest = [];
        orders.forEach((e) => {
            e.element.forEach((_e) => {
                if (
                    _e.eventId &&
                    _e.eventId.id === selectedEvent &&
                    _e.tickets.length !== 0
                ) {
                    users.forEach((u) => {
                        if (u.id === e.userid) {
                            _e.tickets.forEach((t) => {
                                guest.push({
                                    name: u.name,
                                    id: e.id,
                                    no: t
                                        .split('ticket_')[1]
                                        .split('_')[1]
                                        .slice(1),
                                    status: e.status,
                                });
                            });
                        }
                    });
                }
            });
        });
        setGuests(guest);
    }, [selectedEvent, orders, users]);

    const onScanHandle = (data) => {
        if (data) {
            setIsScaning(false);

            const id = data.split('_')[0];
            const no = data.split('_')[1]?.slice(1);
            let gueastTickets = [];

            console.log(`no`, data);

            let responseData = {
                message: 'Nincs ilyen jegy',
                style: { backgroundColor: 'red', color: 'black' },
                data: data.replaceAll('_', ' '),
            };
            let guestUpdate = [...guests];

            guestUpdate.forEach((guest) => {
                if (guest.id === id) {
                    gueastTickets.push(guest.no);
                }
            });

            guestUpdate.forEach((guest) => {
                if (guest.id === id && gueastTickets.includes(no)) {
                    if (guest.status.split('_')[0] === 'KEZELT JEGY') {
                        if (!guest.status.split('_').includes(no)) {
                            guest.status += '_' + no;
                            responseData.style = {
                                backgroundColor: 'green',
                                color: 'black',
                            };
                        }
                    } else {
                        guest.status = 'KEZELT JEGY_' + no;
                        responseData.style = {
                            backgroundColor: 'green',
                            color: 'black',
                        };
                    }
                    responseData.message =
                        guest.name + ' ' + guest.no + ' - ' + guest.status;
                    dispatch(updateOrder(id, guest.status));
                } else if (id === 'Tiszteletjegy') {
                    responseData = {
                        message: 'Tisztelet jegy',
                        style: { backgroundColor: 'green', color: 'black' },
                    };
                } else if (no === 'talás') {
                    responseData = {
                        message: 'Utalással fizetett jegy',
                        style: { backgroundColor: 'green', color: 'black' },
                    };
                } else if (id === 'test-good') {
                    responseData = {
                        message: 'Helyes teszt jegy',
                        style: { backgroundColor: 'yellow', color: 'black' },
                    };
                }
            });

            setGuests(guestUpdate);
            setScanData(responseData);
        }
    };

    return (
        <div className="flex justify-center bg-black min-h-screen text-white">
            <div className="flex flex-col w-11/12 bg-grey h-auto p-6 shadow-md m-8 rounded-xl">
                <div className="text-3xl my-4 font-bold text-center mb-8 ">
                    Vendégek
                </div>
                <div className="mx-auto w-80 h-80 flex justify-center items-center">
                    {isScaning ? (
                        <QrReader
                            delay={500}
                            onError={(err) => console.error(err)}
                            onScan={(data) => onScanHandle(data)}
                            style={{ width: '100%' }}
                        />
                    ) : (
                        <Button onClick={() => setIsScaning(true)}>
                            Szkennelés
                        </Button>
                    )}
                </div>
                <div
                    className={`justify-center rounded-lg p-4 mx-auto`}
                    style={scanData.style}
                >
                    <p className="text-xl text-center font-bold">
                        {scanData.message}
                    </p>
                    <p className="text-center">{scanData.data}</p>
                </div>
                <div className="my-8 flex flex-row justify-evenly">
                    {filtedEvents?.map((e) => (
                        <Button
                            key={e.id}
                            onClick={() => setSelectedEvent(e.id)}
                            className={
                                e.id === selectedEvent
                                    ? 'bg-blue text-black h-auto'
                                    : 'h-auto'
                            }
                        >
                            {new Date(e.date).toISOString().split('T')[0]}
                        </Button>
                    ))}
                </div>
                <div className="mx-auto my-4 grid grid-cols-6">
                    {guests?.map((g, idx) => {
                        return (
                            <>
                                <div
                                    style={
                                        g.status.split('_')[0] ===
                                            'KEZELT JEGY' &&
                                        g.status.split('_').includes(g.no)
                                            ? { backgroundColor: 'green' }
                                            : {}
                                    }
                                >
                                    {idx + 1}.
                                </div>
                                <div
                                    className={`col-span-3`}
                                    style={
                                        g.status.split('_')[0] ===
                                            'KEZELT JEGY' &&
                                        g.status.split('_').includes(g.no)
                                            ? { backgroundColor: 'green' }
                                            : {}
                                    }
                                >
                                    {g.name}
                                </div>
                                <div
                                    className="text-center"
                                    style={
                                        g.status.split('_')[0] ===
                                            'KEZELT JEGY' &&
                                        g.status.split('_').includes(g.no)
                                            ? { backgroundColor: 'green' }
                                            : {}
                                    }
                                >
                                    {g.no}
                                </div>
                                <div
                                    className="text-right"
                                    style={
                                        g.status.split('_')[0] ===
                                            'KEZELT JEGY' &&
                                        g.status.split('_').includes(g.no)
                                            ? {
                                                  backgroundColor: 'green',
                                              }
                                            : {}
                                    }
                                >
                                    {g.status.split('_')[0] === 'KEZELT JEGY' &&
                                    g.status.split('_').includes(g.no)
                                        ? 'KEZELT JEGY'
                                        : 'FINISHED'}
                                </div>
                            </>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default QrPage;

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../redux/actions/auth';
import TextField from '../components/textfield';
import PasswordField from '../components/passwordfield';
import Button from '../components/button';
import history from '../utils/history';

function Login() {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const { handleSubmit, register, errors } = useForm();
    const loginError = false;
    const loginLoading = false;
    const onSubmit = async (values) => {
        dispatch(getToken(values.email, values.password));
    };

    useEffect(() => {
        if (token.length > 0) {
            history.push('/admin');
        }
    }, [token]);

    return (
        <div className="w-screen h-screen lg:p-20 bg-black flex items-center justify-center">
            <div className="lg:w-5/12 h-auto text-white p-8 bg-grey shadow-md">
                Bejelentkezés
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        type="email"
                        label="Email"
                        name="email"
                        ref={register({
                            required: 'Írd be az email címedet',
                        })}
                        errors={errors}
                    />

                    <PasswordField
                        label="Jelszó"
                        name="password"
                        ref={register({
                            required: 'Add meg a jelszavadat',
                        })}
                        errors={errors}
                    />
                    <div className="text-red-700">
                        {loginError ? (
                            <span className="inline-block text-sm mt-1">
                                Belépés hiba
                            </span>
                        ) : null}
                    </div>

                    <div className="flex mt-8 mb-8">
                        <Button
                            type="submit"
                            size="md"
                            className="ml-auto"
                            loading={loginLoading}
                        >
                            login
                        </Button>
                    </div>

                    {/* <div>
            <span className="inline-block mr-2 text-sm">Nincs még fiókod?</span>
            <Link
              to="/register"
              className="text-sm text-link-color hover:text-primary-600
              transition-all duration-200 ease-in-out"
            >
              Regisztráció
            </Link>
          </div> */}
                </form>
            </div>
        </div>
    );
}

export default Login;

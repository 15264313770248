import { getUsers, getOrders, getProducts, getEvents } from './api';

const qs = require('query-string');

const apiURL = "https://enszinhazambackend.herokuapp.com";
//const apiURL = 'http://localhost:9000';
const masterKey = '2w7I63elUBAWPBT8V37MY4xFZjFpMJnR';
const axios = require('axios');

export function deleteUser(userid) {
    return async (dispatch, getState) => {
        return axios
            .delete(
                apiURL + '/users/' + userid + '?access_token=' + getState().auth.token,
                {},
                {
                }
            )
            .then((response) => {
                dispatch({ type: 'DELETE_USER' });
                dispatch(getUsers())

            })
            .catch(function (error) {
                alert('Hiba, kérlek próbáld újra');
            });
    };
}
export function deleteOrder(id) {
    return async (dispatch, getState) => {
        return axios
            .delete(
                apiURL + '/orders/' + id + '?access_token=' + getState().auth.token,
                {},
                {
                }
            )
            .then((response) => {
                dispatch({ type: 'DELETE_ORDER' });
                dispatch(getOrders())

            })
            .catch(function (error) {
                alert('Hiba, kérlek próbáld újra');
            });
    };
}
export function deleteEvent(id) {
    return async (dispatch, getState) => {
        return axios
            .delete(
                apiURL + '/events/' + id + '?access_token=' + getState().auth.token,
                {},
                {
                }
            )
            .then((response) => {
                dispatch({ type: 'DELETE_EVENT' });
                dispatch(getEvents())

            })
            .catch(function (error) {
                alert('Hiba, kérlek próbáld újra');
            });
    };
}
export function deleteProduct(id) {
    return async (dispatch, getState) => {
        return axios
            .delete(
                apiURL + '/products/' + id + '?access_token=' + getState().auth.token,
                {},
                {
                }
            )
            .then((response) => {
                dispatch({ type: 'DELETE_PRODUCTS' });
                dispatch(getProducts())

            })
            .catch(function (error) {
                alert('Hiba, kérlek próbáld újra');
            });
    };
}

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { getCookie } from './utils/cookies';

import Login from './screens/Login';
import Register from './screens/Register';
import Admin from './screens/Admin';
import Profile from './screens/Profile';
import Users from './screens/Users';
import Products from './screens/Products';
import Events from './screens/Events';
import Orders from './screens/Orders';
import QrPage from './screens/QrPage';

function Routes() {
    const token = getCookie('token');
    const role = getCookie('userrole');
    const userId = getCookie('userid');

    console.log(`role`, role, userId);

    let route = (
        <Switch>
            <Route path="/login" component={Login} />
            <Route path="/admin" component={Admin} />
            <Route
                path="/"
                exact
                render={() =>
                    token ? <Redirect to="/admin" /> : <Redirect to="/login" />
                }
            />
        </Switch>
    );

    if (role === 'admin' && userId === 'ticket') {
        route = (
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/register" component={Register} />
                <Route path="/admin" component={Admin} />
                <Route path="/qr" component={QrPage} />
                <Route
                    path="/"
                    exact
                    render={() =>
                        token ? (
                            <Redirect to="/admin" />
                        ) : (
                            <Redirect to="/login" />
                        )
                    }
                />
            </Switch>
        );
    }

    if (role === 'admin' && userId !== 'ticket') {
        route = (
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/register" component={Register} />
                <Route
                    path="/"
                    exact
                    render={() =>
                        token ? (
                            <Redirect to="/admin" />
                        ) : (
                            <Redirect to="/login" />
                        )
                    }
                />
                <Route path="/admin" component={Admin} />
                <Route path="/users" component={Users} />
                <Route path="/products" component={Products} />
                <Route path="/events" component={Events} />
                <Route path="/profile" component={Profile} />
                <Route path="/qr" component={QrPage} />
                <Route path="/orders" component={Orders} />
            </Switch>
        );
    }

    return route;
}

export default Routes;
